//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import $ from 'jquery';
import { mapState } from 'vuex';
import * as MenuLayout from '~/components/menu'
import CountryDropdown from '~/components/menu/CountryDropdown';

import Header from "~/content/data/header.json"
export default {
  name: 'PageHeader',
  components: {
    ...MenuLayout,
    CountryDropdown
  },
  data(){
    return {
      active: null,
      sticky: false,
      lastScrollTop: 0,
      lastScrollDelta: 0,
      scrolled: false,
      scrolling: false,
      scrollingTimeout: null,
      navDesktopOpen: false,
      open: false,
      megaMenuStyle: {
        'height': '500px'
      },
      allowNavOpen: {"top": 0,"left":0,"bottom":0,"right":0}
    }
  },
  computed:{
    ...mapState({
      page: state => state.pages.page,
    }),
    hoverLineStyle(){
      if(this.page.sticky){
        return {
          'left' : 600,
          'top' : '15px',
          'height' : '46px',
          'width' : '120px'
        }
      }
      return {
        'left' : 600,
        'top' : '38px',
        'height' : '46px',
        'width' : '120px'
      }
    },
    header: () => Header,
    theme(){
      if(this.page && this.page.header) return 'navbar-' + (this.page.header.theme || 'dark');
      return 'navbar-dark';
    },
    _headerLogo(){
      if(this.theme === 'navbar-dark') return require('~/static/uploads/conversr-logo-kudo-white.svg');
      // if(this.open) return require('~/assets/images/burst-logo-white.svg');
      return require('~/assets/images/conversr-logo-kudo.svg');
    }
  },
  methods: {
    _checkIfBounds(position,bounds){
      return (position.x <= bounds.right && position.x >= bounds.left && position.y >= bounds.top && position.y <= bounds.bottom )
    },
    _getTemplate(template){
      return 'menu-' + this.$options.filters.slugify(template);
    },
    _openNaV(){
      // this.open = true;
      // $('body').addClass('nav--open');
    },
    _navClose(event){
      if(event){
        if(this._checkIfBounds({ x: event.clientX, y: event.clientY }, this.allowNavOpen)) return;
      }
      this.open = false;
      this._setActiveHover(null);
      $('body').removeClass('nav--open');
      $('body').removeClass('nav-mobile--open');
    },
    _hoverMenu($event,m){

      const self = this;

      self._setActiveHover(m);

      const $elem = $event.target;

      $($elem).addClass('hover').siblings().removeClass('hover');

      let $elemTab = null;

      self.hoverLineStyle.width = $elem.clientWidth + 10 + 'px';
      self.hoverLineStyle.height = '50px';
      self.hoverLineStyle.left =   $elem.getBoundingClientRect().left - 5 + 'px';

      const $elemContent = self.$refs["mega-content-" + m][0];

      if($elemContent){

        // Force Activate Tab
        $elemTab = $($elemContent).find('[data-toggle="tab"]').first();
        if($elemTab){
          $elemTab.tab('show').on('shown.bs.tab', function (e) {
            $(e.relatedTarget).closest('.megamenu').css({
              'height': $(this).closest('.menu-content').height()
            });

            self.$forceUpdate();
          });
        }

        // Update Megamenu Height
        self.megaMenuStyle.height = $elemContent.clientHeight + 'px';
      }

      self.$forceUpdate();

    },
    _megamenuUpdate(){
      if(!this.active) return;

      const $elemContent = this.$refs["mega-content-" + this.active][0];
      if($elemContent){
        this.megaMenuStyle.height = $elemContent.clientHeight + 'px';
      }
    },
    _setActiveHover(m){
      this.$set(this,'active',m);
      this.$forceUpdate();
    },


    // Mobile Nav
    _toggleNavMobile(){
      $('body').toggleClass('nav-mobile--open');
    },

    // Get NavLinks Bounds

    _getNavLinksBounds(){
      // Get navbar links coordinates
      const $navMain = $('.navbar-links').first();
      const navMainPos = $navMain.offset();

      navMainPos.bottom = navMainPos.top + $navMain.height() + 34;
      navMainPos.right = navMainPos.left + $navMain.width();

      this.allowNavOpen = navMainPos;
    }

  },
  created(){
    if(process.browser){
      const self = this;
      $(function(){
        $(window).scroll(function(){


          if(self.open){
            self._navClose();
          }

        });
        $('body').on('click','[aria-current="page"]',function(event){
          event.preventDefault();

          self._navClose();
          window.location.assign($(this).attr('href'));
          // self.$router.push({
          //   path: $(this).attr('href')
          // });
        });
        $('#navbar-header [href="/pricing"]').click(function(event){
          event.preventDefault();

          self.$router.push({
            path: '/pricing'
          });
          self._navClose();
        });
        // $('#navbar-header [href="#turnkey-solutions"]').click(function(event){
        //   event.preventDefault();
        //   const el = document.querySelector('#turnkey-solutions');
        //
        //   if (el) {
        //     // el.scrollIntoView({behavior: 'smooth'});
        //     el.scrollIntoView(); // we want instant transition
        //   }
        // });
        $('#navbar-header [href*="#book-a-demo"]').click(function(event){
          event.preventDefault();
          const el = document.querySelector('#form-request');
        
          if (el) {
            // el.scrollIntoView({behavior: 'smooth'});
            el.scrollIntoView(); // we want instant transition
          }
        });
        $('[href="#live-chat"]').click(function(event){
          event.preventDefault();
          if(window.fcWidget){
            window.fcWidget.open();
            window.fcWidget.show();
          }else{
            window.location.assign('https://portal.burstsms.com/s/submit-ticket')
          }


          if(self.open){
            self._navClose();
          }
        });


        self._getNavLinksBounds();

        $(window).resize(function(){
          self._getNavLinksBounds();
        });

      });
    }

    this.$router.beforeEach((to, from, next) => {
      if(to.name !== "slug"){
        window.location.assign(to.path.replace('/http','http'));
        next(false);
      }else{
        this._navClose();
        next();
      }

    })
  }
}
